<template>
  <div class="require-stocks">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu yêu cầu XNK'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-md-5">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
            >
              <template slot="button-content">
                <i
                  style="font-size: 1rem"
                  class="fas fa-cog"
                ></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i
                    style="font-size: 1rem"
                    class="far fa-file-excel"
                  ></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row mb="5" v-if="warningNew">
                <b-col md="12">
                    <p class="text-danger">
                        <span>&#42;</span> Bạn có <span class="hold-report">{{warningReport.overNew24Hours}}</span> phiếu XNK chưa duyệt
                            vượt quá <span class="hold-report">24h</span>, và <span class="hold-report">{{warningReport.overNew48Hours}}</span>
                            phiếu XNK chưa duyệt vượt quá <span class="hold-report">48h</span>.
                    </p>
                </b-col>
        </b-row>
        <b-row mb="5" v-if="warningApprove">
                <b-col md="12">
                    <p class="text-danger">
                        <span>&#42;</span> Bạn có <span class="hold-report">{{warningReport.overApprove24Hours}}</span> phiếu XNK chờ xác nhận
                            vượt quá <span class="hold-report">24h</span>, và <span class="hold-report">{{warningReport.overApprove48Hours}}</span>
                            phiếu XNK chờ xác nhận vượt quá <span class="hold-report">48h</span>.
                    </p>
                </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="productName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchStock"
              :suggestions="filteredOptions"
              placeholder="kho nguồn"
              :limit="10"
              @select="onSelected"
              @change="onInputChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchDestinationStock"
              :suggestions="filteredDStockOptions"
              placeholder="kho đích"
              :limit="10"
              @select="onDestinationSelected"
              @change="onSearchDestinationChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu/ ID Nhanh"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchIMEI"
              type="text"
              placeholder="Nhập IMEI sản phẩm"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchDesciption"
              type="text"
              placeholder="Tìm kiếm"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->

        <ListRequiredStockToolBar
          v-show="hasSelectedRequiredStocks"
          :selectedRequiredStocks="selectedRequiredStocks"
          @showDialogSelectedRequiredStocks="handleShowDialogSelectedRequiredStocks"
        />

        <b-table
          :items="stocks"
          :fields="fields"
          sort-by="count"
          class="table-bordered table-hover col-md-12"
          :busy="onLoading"
          selected-variant="success"
          :tbody-tr-class="rowClass"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>

          <template #head(index)>
            <b-form-checkbox
              size="lg"
              class="text-center"
              @change="selectAll"
              :checked="isCheckAll"
            ></b-form-checkbox>
          </template>

          <template v-slot:cell(index)="row">
            <b-form-checkbox
              size="lg"
              class="text-center"
              name="selected-items"
              v-model="selectedRequiredStocks"
              :value="row.item"
            >
            </b-form-checkbox>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span
                v-text="row.item.count"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(countProduct)="row">
            <div style="text-align: right">
              <span
                v-text="row.item.countProduct"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(countQuantity)="row">
            <div style="text-align: right">
              <span
                v-text="row.item.countQuantity"
                class="text-right"
              ></span>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <div style="
                justify-content: center;
                align-items: center;
                display: grid;
              ">
              <a
                class="font-weight-bold"
                v-bind:href="getLinkDoc(row.item)"
              >
                {{ row.item.code }}
              </a>
            </div>
            <div
              v-if="row.item.idNhanh"
              class="text-primary"
            >
              <span>ID Nhanh:</span>
              <span style="font-size: 11px">( {{ row.item.idNhanh }} )</span>
            </div>
          </template>
          <template v-slot:cell(relateStockCode)="row">
            <div
              class="productCode"
              @click="viewTransferStock(row.item)"
              style="cursor: pointer"
            >
              <span v-text="row.item.relateStockCode"></span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="row">
            <p class="text-center">{{ row.item.createdAt }}</p>
            <p class="text-center">{{ row.item.createBy }}</p>
          </template>

          <template v-slot:cell(approve)="row">
            <div
              v-if="row.item.status === 1"
              style="cursor: pointer"
              @click="showApproveAlert(row.item.id)"
              class="d-flex justify-content-center align-items-center"
            >
              <v-icon
                small
                style="font-size: 13px"
                v-b-tooltip
                title="Duyệt phiếu"
                v-if="checkPermission('STOCK_UPDATE')"
              >menu-icon flaticon2-checking</v-icon>
            </div>
            <div v-else>
              <p class="text-center text-primary mb-0">Đã duyệt</p>
              <p class="text-center">{{ row.item.approveBy }}</p>
              <p class="text-center text-primary mb-0">
                {{ row.item.approveDate }}
              </p>
              <p
                v-show="row.item.issuePortalFastStatus.hasShown"
                class="text-center text-primary mb-0"
                v-b-tooltip.hover.bottom
                :title="row.item.issuePortalFastStatus.name"
              >
                <span style="color: #3f4254; font-size: 12px">
                  <i :class="`${row.item.issuePortalFastStatus.iconClassName} icon-md`"></i>
                  &nbsp;
                </span>
              </p>
            </div>
          </template>
          <template v-slot:cell(confirm)="row">
            <div
              v-if="row.item.status === 2 && row.item.status !== 1"
              @click="showConfirmAlert(row.item)"
              style="cursor: pointer"
              class="d-flex justify-content-center align-items-center"
            >
              <v-icon
                small
                style="font-size: 13px"
                v-b-tooltip
                title="Xác nhận phiếu"
                v-if="checkPermission('STOCK_UPDATE')"
              >menu-icon flaticon2-checkmark</v-icon>
            </div>

            <div v-else-if="row.item.status === 3">
              <p class="text-center text-primary mb-0">Đã xác nhận</p>
              <p class="text-center">{{ row.item.confirmBy }}</p>
              <p class="text-center text-primary mb-0">
                {{ row.item.confirmDate }}
              </p>
            </div>
          </template>

          <template v-slot:cell(sourceWarehouseName)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.sourceWarehouseName"></span>
              <br />
              <i
                style="font-size: 1rem; color: blue"
                class="flaticon2-right-arrow"
              ></i>
              <br />
              <span v-text="row.item.destinationWarehouseName"></span>
            </div>
          </template>
          <template v-slot:cell(description)="row">
            <div
              @mouseover="hoverPrice = true"
              @mouseleave="hoverPrice = false"
            >
              <span
                v-text="row.item.description"
                class="mr-2"
              ></span>
              <span @click="handleShowNoteModel(row.item)">
                <i
                  v-if="hoverPrice"
                  class="fas fa-edit"
                ></i>
              </span>
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-printer"
                    ></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="row.item.status === 1"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showModalCancel(row.item.id)"
                  v-if="row.item.status === 2"
                  v-show="checkPermission('STOCK_CANCEL_TRANSFER')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i class="fas fa-ban"></i>
                    &nbsp; Hủy duyệt phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="
                    checkPermission('STOCK_DELETE') ||
                    checkPermission('STOCK_REMOVE_TRANSFER')
                  "
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchStock"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <DeleteTransferReason
          ref="delete-transfer-modal"
          :stockSlipCode="stockSlipCode"
          :stockSlipId="stockSlipId"
          v-on:onSuccess="fetchStock"
        />
        <!-- End of Paginate session -->
        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
          >
            <b-row class="mb-5">
              <b-col>
                <label
                  class="labelInput"
                  for="inputOriginalPrice"
                >Ghi chú</label>
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                >Lưu</b-button>
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                >Hủy</b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </template>
    </KTCodePreview>
    <ListRequireStockSelectedModal @resetSelectedRequiredStocks="handleResetSelectedRequiredStocks" />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice, makeToastFaile, makeToastSuccess } from '@/utils/common';
import { BASE_URL, TIME_TRIGGER } from '@/utils/constants';
import fileDownload from '@/utils/file-download';
import localData from '@/utils/saveDataToLocal';
import { showConfirmNotify } from '@/utils/sweet-alert2';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import DeleteTransferReason from '@/view/components/stock-slips/DeleteTransferReason';
import KTCodePreview from '@/view/content/CodePreview.vue';
import axios from 'axios';
import decounce from 'debounce';
import moment from 'moment';
import Swal from 'sweetalert2';
import { VclTable } from 'vue-content-loading';
import { vi } from 'vuejs-datepicker/dist/locale';
import { EventBus } from '@/core/services/event-bus';

const ListRequiredStockToolBar = () =>
  import(
    /* webpackPrefetch: true */ '@/view/pages/stocks/ListRequiredStockToolBar.vue'
  );
const ListRequireStockSelectedModal = () =>
  import(
    /* webpackPrefetch: true */ '@/view/pages/stocks/ListRequireStockSelectedModal.vue'
  );

export default {
  data() {
    return {
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      stocks: [],
      searchDesciption: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      searchIMEI: '',
      type: 5,
      status: 'not_accepted',
      selectedRequiredStocks: [],
      fields: [
        {
          key: 'index',
          label: 'Index',
          thStyle: {
            textAlign: 'center',
            width: '5%',
          },
        },
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'sourceWarehouseName',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'relateStockCode',
          label: 'Từ phiếu CK',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'countProduct',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'countQuantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'approve',
          label: 'Duyệt',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'confirm',
          label: 'Xác nhận',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      optionStores: [],
      filteredOptions: [],
      limit: 10,
      searchDestinationStock: '',
      selectedDStock: '',
      sourceWarehouseId: null,
      options: [
        {
          data: [],
        },
      ],
      destinationWarehouseId: null,
      filteredDStockOptions: [],
      searchProduct: '',
      selectedStatus: 0,
      listStatus: [
        {
          id: 0,
          name: 'Chọn trạng thái',
        },
        {
          id: 1,
          name: 'Chưa duyệt',
        },
        {
          id: 2,
          name: 'Đã duyệt',
        },
        {
          id: 3,
          name: 'Đã xác nhận',
        },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [],
      hoverPrice: false,
      stockSlipCode: '',
      showEditPriceModal: false,
      note: '',
      userStoreId: null,
      stockSlipId: null,
      warningNew: true,
      warningApprove: true,
      warningReport: {
        overNew24Hours: 0,
        overNew48Hours: 0,
        overApprove24Hours: 0,
        overApprove48Hours: 0,
      },
      isIssuing: false,
    };
  },
  computed: {
    isCheckAll() {
      return this.selectedRequiredStocks.length === this.stocks.length;
    },
    hasSelectedRequiredStocks() {
      return (
        this.selectedRequiredStocks && this.selectedRequiredStocks.length > 0
      );
    },
  },
  methods: {
    handleResetSelectedRequiredStocks() {
      this.selectedRequiredStocks = [];
      this.fetchStock();
    },
    handleShowDialogSelectedRequiredStocks({
      mode,
      validSelectedRequiredStocksToIssue,
    }) {
      EventBus.$emit('selected-require-stocks-modal', {
        selectedRequiredStocks: validSelectedRequiredStocksToIssue,
        mode: mode,
      });
    },
    selectAll() {
      if (this.selectedRequiredStocks.length === this.stocks.length) {
        this.selectedRequiredStocks = [];
      } else {
        this.selectedRequiredStocks = this.stocks.slice();
      }
    },
    rowClass(item) {
      return this.selectedRequiredStocks.indexOf(item) > -1
        ? 'table-secondary'
        : '';
    },
    details(item) {
      alert(JSON.stringify(item));
    },
    convertPrice,
    fetchStore: async function () {
      this.optionStores = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionStores = stores.map((element) => {
          return {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
        this.filteredOptions = [...this.optionStores];
        this.filteredDStockOptions = [...this.optionStores];
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        page: this.page,
        pageSize: 10,
        search: this.searchDesciption ? this.searchDesciption.trim() : '',
        searchId: this.searchId ? this.searchId.trim() : '',
        status: this.selectedStatus,
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00',
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59',
            )
          : '',
        searchStock: this.searchStock,
        type: this.type,
        searchDestinationStock: this.searchDestinationStock,
        searchProduct: this.searchProduct,
        searchIMEI: this.searchIMEI.trim() || '',
      };

      ApiService.query('requireStocks/getAll', { params })
        .then((response) => {
          this.stocks = [];
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          this.stocks = stockResponse.map((item, index) => {
            return {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              sourceWarehouseName: item.sourceWarehouseName,
              destinationWarehouseId: item.destinationWarehouseId,
              destinationWarehouseName: item.destinationWarehouseName,
              providerId: item.providerId,
              description: item.description,
              status: item.status,
              totalAmount: item.totalAmount,
              type: item.type,
              createBy: item.createBy,
              updateBy: item.updateBy,
              createdAt: moment(String(item.createdAt)).format('DD/MM/YYYY'),
              approveBy: item.approveBy,
              approveDate: moment(String(item.approveDate)).format(
                'DD/MM/YYYY HH:mm:ss',
              ),
              confirmBy: item.confirmBy,
              confirmDate: moment(String(item.confirmDate)).format(
                'DD/MM/YYYY HH:mm:ss',
              ),
              relateStock: item.relateStockSlipId,
              relateStockCode: item.relateStockCode,
              countProduct: item.countProduct,
              countQuantity: item.countQuantity,
              idNhanh: item.idNhanh,
              issuePortalFastStatus: item.issuePortalFastStatus,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-transfer-stock',
        query: { id: item.relateStock },
      });
    },
    onSelected(option) {
      this.sourceWarehouseId = option.item.id;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      const filteredData = this.optionStores
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [...filteredData];
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu chuyển kho!',
        text: 'Bạn có chắc muốn xóa phiếu chuyển kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          if (this.checkPermission('STOCK_REMOVE_TRANSFER')) {
            this.showDeleteModal(item);
          } else if (this.checkPermission('STOCK_DELETE')) {
            this.deleteItem(item);
          }
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`stock-slips/transfer/${item.relateStock}`).then(
        ({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.fetchStock();
          } else {
            makeToastFaile(data.message);
          }
        },
      );
    },
    showApproveAlert: function (id) {
      const { href } = this.$router.resolve({
        name: 'approve-transfer-stock',
        query: { id: id },
      });
      window.open(href, '_blank');
    },
    showConfirmAlert: function (item) {
      //kiểm tra nếu thuộc kho nhận thì mới có quyền xác nhận phiếu
      if (
        item.destinationWarehouseId === this.userStoreId ||
        this.userStoreId === null
      ) {
        const { href } = this.$router.resolve({
          name: 'confirm-transfer-stock',
          query: { id: item.id },
        });
        window.open(href, '_blank');
      } else {
        const message = `Bạn không thể xác nhận phiếu do tài khoản bạn không có quyền xác nhận phiếu chuyển kho đến: ${item.destinationWarehouseName}`;
        makeToastFaile(message);
      }
    },
    viewTransferStock: function (item) {
      this.$router.push({
        name: 'update-transfer-stock',
        query: { id: item.relateStock },
      });
    },
    onDestinationSelected(option) {
      this.destinationWarehouseId = option.item.id;
      this.searchDestinationStock = option.item.name;
    },
    onSearchDestinationChange(text) {
      if (!text) {
        text = '';
      }
      this.searchDestinationStock = text;

      const filteredData = this.optionStores
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredDStockOptions = [...filteredData];
    },
    fetchProduct() {
      this.optionsProduct = [];
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`,
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.selectedRequiredStocks = [];
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-require-stock',
      });
      this.fetchStock();
      this.fetchOverTransfer();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    reportClick: async function () {
      const url = `${BASE_URL}stocks/export-excel-stock-slip`;
      const params = {
        searchDesciption: this.searchDesciption
          ? this.searchDesciption.trim()
          : '',
        searchCode: this.searchId ? this.searchId.trim() : '',
        status: this.selectedStatus,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00',
            )
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59',
            )
          : '',
        searchStock: this.searchStock,
        type: '5',
        searchDestinationStock: this.searchDestinationStock,
        searchProduct: this.searchProduct,
      };
      await axios({
        url: url,
        method: 'GET',
        params: {
          ...params,
        },
        responseType: 'blob',
      })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch(async (error) => {
          const responseObj = JSON.parse(await error.response.data.text());
          return makeToastFaile(responseObj.message || 'Lỗi');
        });
    },
    getLinkDoc(item) {
      if (item.status === 1) {
        return `#/require-stocks/approve-transfer-stock?id=${item.id}`;
      } else if (item.status === 2) {
        return `#/require-stocks/confirm-transfer-stock?id=${item.id}`;
      } else {
        return `#/require-stocks?code=${item.code}`;
      }
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.stockSlipCode = item.code;
      this.note = item.description;
    },
    updateNote() {
      const data = {
        stockSlipCode: this.stockSlipCode,
        description: this.note,
      };
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchStock();
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.userStoreId = stores[0].id;
          }
        }
      });
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-transfer-stockslip',
        query: { id: item.relateStock },
      });
    },
    async onIssueToFastPortal(requiredStockSlip) {
      const content = {
        title: `Phát hành phiếu #${requiredStockSlip.code}`,
        text: `Bạn có chắc muốn phát hành phiếu yêu cầu chuyển kho này không ?`,
        actionName: 'Xác nhận',
        confirmButtonColor: '#3699FF',
      };
      const result = await showConfirmNotify(content);
      if (!result.value) return;

      this.issueStockToFastPortal(requiredStockSlip);
    },
    async issueStockToFastPortal(requiredStockSlip) {
      if (this.isIssuing) return;
      try {
        this.isIssuing = !this.isIssuing;
        const res = await ApiService.post(
          `stock-slips/${requiredStockSlip.id}/issue-transfer`,
        );
        makeToastSuccess(res.data.message);
        this.fetchStock();
      } catch (error) {
        makeToastFaile(error.response.data.message);
      } finally {
        this.isIssuing = false;
      }
    },
    showModalCancel: function (id) {
      Swal.fire({
        title: 'Hủy phiếu yêu cầu chuyển kho!',
        text: 'Bạn có chắc muốn hủy phiếu yêu cầu chuyển kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.cancelItem(id);
        }
      });
    },
    cancelItem: async function (id) {
      ApiService.put(`stock-slips/cancel-transfer/${id}`)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.fetchStock();
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.data.message);
        });
    },
    showDeleteModal(item) {
      this.stockSlipCode = item.code;
      this.stockSlipId = item.relateStock;
      setTimeout(() => {
        this.$refs['delete-transfer-modal'].showModal();
      }, TIME_TRIGGER);
    },
    fetchOverTransfer: function() {
      const params = {
        sourceWarehouseId: this.sourceWarehouseId,
        destinationWarehouseId: this.destinationWarehouseId,
      }
      ApiService.query(`/stock-slips/over-transfer`, { params })
            .then(response => {
                this.warningReport = response.data.data;
            })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu XNK', route: 'require-stocks' },
      { title: 'Danh sách phiếu yêu cầu XNK' },
    ]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.fetchStock();
    this.fetchStore();
    this.fetchStoreByUser();
    this.fetchOverTransfer();
  },
  components: {
    KTCodePreview,
    Autosuggest,
    VclTable,
    DeleteTransferReason,
    ListRequiredStockToolBar,
    ListRequireStockSelectedModal,
  },
};
</script>

<style lang="scss" scoped>
.require-stocks {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }
  .productCode:hover {
    text-decoration: underline;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
